<template>
  <!-- 结算管理 - 开票管理 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">待催收列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox"  style="margin-bottom:15px">
            <div title="结算单号" class="searchboxItem ci-full">
              <span class="itemLabel">结算单号:</span>
              <el-input v-model="seachData.billNo" type="text" size="small" placeholder="请输入结算单号" clearable />
            </div>
            <div title="开班机构" class="searchboxItem ci-full">
              <span class="itemLabel">开班机构:</span>
              <el-select size="small" v-model="seachData.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="付款机构" class="searchboxItem ci-full">
              <span class="itemLabel">付款机构:</span>
              <el-input v-model="seachData.payerName" type="text" size="small" placeholder="请输入付款机构" clearable />
            </div>
          </div>
          <div class="searchbox">
            <div title="代付款单位" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">代付款单位:</span>
              <el-input v-model.trim="seachData.replacePayerName" clearable placeholder="请输入代付款单位"
                size="small"></el-input>
            </div>
            <div class="df">
              <el-button type="primary" size="small" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" size="small" class="bgc-bv" round
                @click="addWaitCollection(multipleTable)">生成催收单</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" row-key="billId" @selection-change="selectionChange" :header-cell-style="tableHeader"
              stripe>
              <el-table-column type="selection" width="50" align="center" fixed
                :reserve-selection="true"></el-table-column>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="50" />
              <el-table-column label="结算单编号" align="left" show-overflow-tooltip prop="billNo" minWidth="180" />
              <el-table-column label="付款机构" align="left" show-overflow-tooltip prop="payerName" minWidth="200" />
              <el-table-column label="代付款单位" align="left" show-overflow-tooltip prop="replacePayerName"
                min-width="180px" />
              <el-table-column label="开班机构" align="left" show-overflow-tooltip prop="compName" minWidth="200" />
              <el-table-column label="结算金额" align="left" show-overflow-tooltip prop="payerAmount" minWidth="80" />
              <el-table-column label="应收金额" align="left" show-overflow-tooltip prop="settlementAmount" minWidth="80" />
              <el-table-column label="到账金额" align="left" show-overflow-tooltip prop="arrivalAmount" minWidth="80" />
              <el-table-column label="欠款金额" align="left" show-overflow-tooltip prop="arrearsAmount" minWidth="80" />
              <el-table-column label="结算单状态" align="left" show-overflow-tooltip prop="auditType" minWidth="120">
                <template slot-scope="{ row }">
                  {{ $setDictionary('FD_AUDIT_TYPE', row.auditType) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="addWaitCollection([scope.row])">生成催收单</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="生成催收单" :visible.sync="dialogDataState" v-if="dialogDataState" :close-on-click-modal="false"
      width="600px" top="5%" center>
      <el-form ref="dialogForm" :model="dialogData" label-width="110px" size="small">
        <el-form-item label="付款机构名称" prop="payerName"
          :rules="[{ required: true, message: '请输入付款机构名称', trigger: 'blur' }]">
          <el-input v-model="dialogData.payerName" type="text" size="small" placeholder="请输入付款机构名称" clearable />
        </el-form-item>
        <el-form-item label="邮寄地址">
          <el-input v-model="dialogData.address" type="text" size="small" placeholder="请输入邮寄地址" clearable />
        </el-form-item>
        <el-form-item label="收件人姓名">
          <el-input v-model="dialogData.addresseeName" type="text" size="small" placeholder="请输入收件人姓名" clearable />
        </el-form-item>
        <el-form-item label="收件人电话" prop="addresseeMobile"
          :rules="[{ pattern: /^1[3456789]\d{9}$/, message: '请输入正确电话', trigger: ['change', 'blur'] }]">
          <el-input v-model="dialogData.addresseeMobile" type="text" size="small" placeholder="请输入收件人电话" clearable />
        </el-form-item>
        <el-form-item label="催款金额小写">
          <el-input v-model="dialogData.collectionAmount" type="text" size="small" disabled placeholder="请输入催款金额小写"
            clearable />
        </el-form-item>
        <el-form-item label="催款金额大写">
          <el-input v-model="dialogData.collectionAmountChinese" type="text" size="small" disabled placeholder="请输入催款金额大写"
            clearable />
        </el-form-item>
        <el-form-item label="审核人">
          <el-select size="small" v-model="dialogData.reviewerId" clearable placeholder="请选择审核人">
            <el-option v-for="item in reviewerList" :key="item.adminId" :label="item.fullname"
              :value="item.adminId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批人">
          <el-select size="small" v-model="dialogData.approverId" clearable placeholder="请选择审批人">
            <el-option v-for="item in approverList" :key="item.adminId" :label="item.fullname"
              :value="item.adminId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0">
          <el-table :data="dialogData.billList" size="mini" height="150px" stripe style="width: 100%">
            <el-table-column label="序号" align="center" type="index" width="50px" />
            <el-table-column prop="billNo" label="结算单号">
            </el-table-column>
            <el-table-column prop="payerAmount" label="结算金额" width="110">
            </el-table-column>
            <el-table-column prop="settlementAmount" label="应收金额" width="110">
            </el-table-column>
            <el-table-column prop="arrearsAmount" label="欠款金额" width="110">
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDataState = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "waitCollectionList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      seachData: {
        billNo: "",// 结算单号
        compId: "",// 开班机构
        payerName: "",// 付款机构
      },
      multipleTable: [],//多选集合
      companyList: [],//机构list

      // 生成催收单弹窗数据
      dialogDataState: false,
      reviewerList: [],//审核人列表
      approverList: [],//审批人列表
      dialogData: {
        payerName: '',// 付款机构名称
        address: '',// 邮寄地址
        addresseeName: '',// 收件人姓名
        addresseeMobile: '',// 收件人电话
        collectionAmount: '',// 催款金额小写
        collectionAmountChinese: '',// 催款金额大写
        billList: [],//结算单list
        reviewerId: '',// 审核人
        approverId: '',// 审批人
      }
    };
  },
  created() {
    this.getReviewer()
    this.getApprover()
  },
  mounted() { },
  computed: {
  },
  methods: {
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
      };
      if (this.seachData.billNo) {// 结算单号
        params.billNo = this.seachData.billNo;
      }
      if (this.seachData.compId) {// 开班机构
        params.compId = this.seachData.compId;
      }
      if (this.seachData.payerName) {// 付款机构
        params.payerName = this.seachData.payerName;
      }
      if (this.seachData.replacePayerName) {//代付款单位
        params.replacePayerName = this.seachData.replacePayerName;
      }
      this.doFetch({
        url: "/biz/collectionLetter/waitCollectionList",
        params,
        pageNum,
      });
    },
    // 生成催收单
    addWaitCollection(arr) {
      if (arr.length === 0) {
        this.$message.error('请选择结算单！')
        return
      }
      this.$post("/biz/collectionLetter/generateCollectionLetter", arr)
        .then((res) => {
          if (res.status == 0) {
            Object.keys(this.dialogData).forEach(key => { this.dialogData[key] = '' })//清空
            this.dialogData = Object.assign(this.dialogData, res.data)//赋值
            this.dialogData.reviewerId = this.reviewerList[0]?.adminId || ''//默认审核人
            this.dialogData.approverId = this.approverList[0]?.adminId || ''//默认审批人
            this.dialogDataState = true
          }
        })
        .catch(() => {
          return;
        });
    },
    // 多选change
    selectionChange(val) {
      this.multipleTable = val
    },
    // 搜索 - 开班机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 弹窗 获取审核人列表
    getReviewer() {
      this.$post("/biz/collectionLetter/getReviewer", {})
        .then((res) => {
          this.reviewerList = res.data || [];
        })
    },
    // 弹窗 获取审批人列表
    getApprover() {
      this.$post("/biz/collectionLetter/getApprover", {})
        .then((res) => {
          this.approverList = res.data || [];
        })
    },
    // 弹窗 提交
    submitForm() {
      this.$refs['dialogForm'].validate((valid) => {
        if (valid) {
          this.$post("/biz/collectionLetter/insert", this.dialogData)
            .then((res) => {
              this.$message.success('生成催收单成功！')
              this.dialogDataState = false
              this.$refs.multipleTable.clearSelection();
              this.getData(-1)
            })
        }
      });
    },
    // getTableHeight(opDom = true, page = true) {
    //   let tHeight =
    //     window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675) * 16;
    //   if (opDom) {
    //     tHeight -= 40 + 0.675 * 16;
    //   }
    //   if (page) {
    //     tHeight -= 40;
    //   }
    //   this.tableHeight = tHeight;
    // },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog .el-table th {
  background: rgb(92, 107, 232);
  color: rgb(255, 255, 255);
  border-color: rgb(92, 107, 232);
}
</style>
